<script>
import _pick from 'lodash/pick';
import { mapGetters } from 'vuex';
import { genericForms } from '@shared/mixins/forms';
import aiAssistantMixin from '@app/mixins/aiAssistant';
import APIStore from '@app/services/API/Store';
import AILoader from '@app/components/AILoader.vue';

export default {
  mixins: [genericForms, aiAssistantMixin],
  static: {
    cost: 8,
  },
  components: {
    AILoader,
  },
  props: {
    hasExplanation: {
      type: Boolean,
      default: true,
    },
  },
  form: {
    style: {
      type: 'select',
      column: 'is-12',
      label: 'Style de rédaction (obligatoire)',
      options: {
        direct: 'Droit au but',
        storytelling: 'Storytelling (romancé)',
      },
      inputAttrs: {
        required: true,
      },
    },
    title: {
      type: 'text',
      column: 'is-12',
      label: 'Quel est le titre de votre formation ? (obligatoire)',
      tooltipAttrs: { multilined: true },
      tooltip: `
        Afin de générer une page de vente efficace,
        je dois savoir quelle est la thématique de votre formation.
      `,
      inputAttrs: {
        placeholder: 'Faites décoller votre projet grâce au SEO',
        required: true,
        maxlength: 100,
      },
    },
    tone_1: {
      type: 'select',
      column: 'is-4',
      label: 'Ton du texte #1',
      options: {
        empty: 'Aléatoire',
        familiarity: 'Tutoiement',
        formality: 'Vouvoiement',
      },
      inputAttrs: {
        required: true,
      },
    },
    tone_2: {
      type: 'select',
      column: 'is-4',
      label: 'Ton du texte #2',
      options: {
        empty: 'Aléatoire',
        joyful: 'Jovial',
        young: 'Jeune',
        dynamic: 'Dynamique',
      },
      inputAttrs: {
        required: true,
      },
    },
    tone_3: {
      type: 'select',
      column: 'is-4',
      label: 'Ton du texte #3',
      options: {
        empty: 'Aléatoire',
        clickbait: 'Piège à clic (clickbait)',
        secret: 'Énigmatique (Mystérieux)',
        ask: 'Questionnement',
      },
      inputAttrs: {
        required: true,
      },
    },
    outline: {
      type: 'textarea',
      column: 'is-12',
      label: 'Quel est le plan de votre page de vente ?',
      tooltipAttrs: { multilined: true },
      tooltip: `
        Si vous avez déjà un plan ou une structure de page de vente,
        communiquez-moi ce plan.
      `,
      inputAttrs: {
        placeholder: `
- Qu'est-ce que le SEO ?
- Pourquoi travailler le SEO de son site web ?
- Comment améliorer le référencement de mon site web ?
        `.trim(),
        maxlength: 360,
      },
    },
    who: {
      type: 'text',
      column: 'is-12',
      label: 'À qui s\'adresse votre formation ?',
      tooltipAttrs: { multilined: true },
      tooltip: `
        Une page de vente efficace doit s'adresser
        à votre coeur de cible de la manière la plus précise possible.
      `,
      inputAttrs: {
        placeholder: 'les entrepreneurs qui ne veulent pas faire de publicité payante',
        maxlength: 120,
      },
    },
    price: {
      type: 'number',
      column: 'is-12',
      label: 'Quel est le prix de votre formation (même approximatif) ?',
      tooltipAttrs: { multilined: true },
      tooltip: `
        En fonction du prix de votre formation,
        le texte de vente ne sera pas être rédigé
        de la même manière.
      `,
      inputAttrs: {
      },
    },
    value_proposition: {
      type: 'text',
      column: 'is-12',
      label: 'Quel est votre avantage concurrentiel ?',
      tooltipAttrs: { multilined: true },
      tooltip: `
        Expliquez l'avantage concurrentiel de cette formation.
        Ça peut être son prix, le fait qu'elle soit récente
        ou innovante, le fait que vous soyez connu ou reconnu...
      `,
      inputAttrs: {
        placeholder: 'Je suis un développeur avec 20 ans d\'expérience',
        maxlength: 120,
      },
    },
    problem: {
      type: 'text',
      column: 'is-12',
      label: 'Quel problème, rencontré par votre cible, cette formation résout-elle ?',
      tooltipAttrs: { multilined: true },
      tooltip: `
        Expliquez en une phrase le problème ou le besoin qu'a
        votre cible et que votre formation permettra de résoudre.
      `,
      inputAttrs: {
        placeholder: 'Le SEO, c\'est long et trop compliqué à apprendre',
        maxlength: 120,
      },
    },
    requirements: {
      type: 'textarea',
      column: 'is-12',
      label: 'Quels sont les prérequis de votre formation ?',
      tooltipAttrs: { multilined: true },
      tooltip: `
        Listez le ou les prérequis nécessaires pour
        être capable de suivre votre formation (compétences, connaissances, outils, etc).

        Dire qu'il n'y a pas de prérequis est aussi une réponse
        pertinente si vous voulez que ce soit indiqué dans
        la page de vente.
      `,
      inputAttrs: {
        placeholder: `
- aucune compétence technique nécessaire
- il suffit de savoir utiliser un navigateur web
        `.trim(),
        rows: 3,
        maxlength: 240,
      },
    },
    goal: {
      type: 'textarea',
      column: 'is-12',
      label: 'Que va apprendre l\'acheteur ?',
      tooltipAttrs: { multilined: true },
      tooltip: `
        Lister les compétences ou connaissances générales
        que l'acheteur va acquérir durant sa formation.
      `,
      inputAttrs: {
        placeholder: `
- apprendre les bases du SEO
- apprendre à utiliser le SEO pour propulser son projet
        `.trim(),
        rows: 3,
        maxlength: 240,
      },
    },
    result: {
      type: 'textarea',
      column: 'is-12',
      label: 'Quel sera le bénéfice pour l\'acheteur ?',
      tooltipAttrs: { multilined: true },
      tooltip: `
        Expliquez en une phrase les bénéfices de votre formation
        pour votre cible
      `,
      inputAttrs: {
        placeholder: 'Vous saurez attirer plus de clients',
        rows: 2,
        maxlength: 240,
      },
    },
    bio: {
      type: 'textarea',
      column: 'is-12',
      label: 'Quel est votre profil ?',
      tooltipAttrs: { multilined: true },
      tooltip: `
        Votre profil vous permet de crédibiliser, personnaliser et rassurer vos prospects.
        Listez-y par exemple, en 2 ou 3 phrases simples et directes :
        votre expertise pédagogique et technique, vos diplômes et certifications éventuelles,
        vos motivations, ambitions, passions, expériences...
      `,
      inputAttrs: {
        placeholder: `
- je fais du SEO depuis 2012
- j'applique cette stratégie sur plus de 8 sites web
        `.trim(),
        maxlength: 240,
      },
    },
  },
  data() {
    return {
      newOptions: {
        type: 'sales_page',
        style: 'direct',
        title: '',
        outline: '',
        who: '',
        price: '',
        value_proposition: '',
        problem: '',
        requirements: '',
        goal: '',
        result: '',
        bio: '',
        testimonials: '',
        tone_1: 'empty',
        tone_2: 'empty',
        tone_3: 'empty',
        tones: [],
      },
    };
  },
  computed: {
    ...mapGetters('auth', {
      authStore: 'store',
    }),
    baseFields() {
      return _pick(this.$options.form, [
        'style',
        'title',
        'tone_1',
        'tone_2',
        'tone_3',
      ]);
    },
    outlineFields() {
      return _pick(this.$options.form, ['outline']);
    },
    advancedFields() {
      const fields = { ...this.$options.form };

      delete fields.style;
      delete fields.title;
      delete fields.tone_1;
      delete fields.tone_2;
      delete fields.tone_3;
      delete fields.outline;

      return fields;
    },
  },
  methods: {
    action() {
      const newOptions = { ...this.newOptions };

      newOptions.tones = [];

      if (newOptions.tone_1 !== 'empty') {
        newOptions.tones.push(newOptions.tone_1);
      }

      if (newOptions.tone_2 !== 'empty') {
        newOptions.tones.push(newOptions.tone_2);
      }

      if (newOptions.tone_3 !== 'empty') {
        newOptions.tones.push(newOptions.tone_3);
      }

      delete newOptions.tone_1;
      delete newOptions.tone_2;
      delete newOptions.tone_3;

      Object.keys(newOptions)
        .forEach((v) => newOptions[v] === '' && (delete newOptions[v]));

      return APIStore.generateAIContent(this.authStore.uuid, newOptions)
        .then(({ data }) => {
          this.showContent(data);
          this.removeCredits();
        });
    },
  },
};
</script>

<template>
  <div>
    <template v-if="hasExplanation">
      <h3 class="title is-5">
        Rédigez votre page de vente en 3 minutes !
        <b-tooltip position="is-left">
          <template #content>
            <span v-t="'ai_assistant.credit_cost'" />
          </template>
          <span class="tag is-outlined">
            {{ $options.static.cost }}
            crédit{{ $options.static.cost > 1 ? 's' : '' }}
          </span>
        </b-tooltip>
      </h3>
      <div class="content">
        <p>
          Donnez-moi le titre de votre formation, le style de rédaction
          que vous souhaitez, et je me mettrai immédiatement au travail !
          Un bon argumentaire de vente est la clé pour optimiser vos ventes.
        </p>
        <p class="has-text-weight-bold">
          Pour un résultat encore plus pertinent, il est recommandé de remplir également
          les champs complémentaires (en dépliant la section "Avez-vous plus de précisions" ci-dessous).
          Laissez ensuite la magie opérer !
        </p>
      </div>
    </template>

    <form
      class="box"
      @submit.prevent="dataIsValid(isLoading) && hasEnoughCredits() && handle()"
    >
      <div class="columns is-multiline">
        <div
          v-for="(field, k) in baseFields"
          :key="k"
          class="column mb-0"
          :class="field.column"
        >
          <GeneratorField
            :fieldName="k"
            :fieldDefinition="field"
            :formData.sync="newOptions"
          />
        </div>
      </div>
      <hr class="has-background-grey">
      <AppToggleSection v-if="newOptions.style === 'direct'">
        <template #title>
          Vous avez déjà votre plan de page ?
        </template>
        <div class="columns is-multiline">
          <div
            v-for="(field, k) in outlineFields"
            :key="k"
            class="column mb-0"
            :class="field.column"
          >
            <GeneratorField
              :fieldName="k"
              :fieldDefinition="field"
              :formData.sync="newOptions"
            />
          </div>
        </div>
      </AppToggleSection>
      <AppToggleSection>
        <template #title>
          Avez-vous plus de précisions ?
        </template>
        <div class="columns is-multiline">
          <div
            v-for="(field, k) in advancedFields"
            :key="k"
            class="column mb-0"
            :class="field.column"
          >
            <GeneratorField
              :fieldName="k"
              :fieldDefinition="field"
              :formData.sync="newOptions"
            />
          </div>
        </div>
      </AppToggleSection>
      <div class="field has-text-right">
        <b-button
          type="is-primary"
          native-type="submit"
          icon-left="magic"
          :loading="isLoading"
        >
          Générer ma page de vente
        </b-button>
      </div>
    </form>

    <transition name="fade">
      <AILoader v-if="isLoading" />
    </transition>
  </div>
</template>
