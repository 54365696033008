<script>
import sprite from '@app/assets/img/ai_assistant/sprite.png';

((new Image()).src = sprite);

export default {
  data: () => ({
    index: 0,
    current: 'super',
  }),
  mounted() {
    document.body.appendChild(this.$el);
    const timer = setInterval(() => {
      let random;
      const loaders = [
        'bonplan',
        'super',
        'idee',
        'reflexion',
        'ecriture',
      ];

      do {
        random = Math.floor(Math.random() * loaders.length);
      } while (random === this.index);

      this.index = random;
      this.current = loaders[this.index];
    }, 3500);
    this.$on('hook:destroyed', () => {
      clearInterval(timer);
      if (this.$el.parentElement === document.body) {
        document.body.removeChild(this.$el);
      }
    });
  },
};
</script>

<template>
  <div class="ailoader p-10">
    <div class="ailoader_img" :class="`bg-${current}`" />
    <div
      class="box mw-460 w-full ailoader_text is-size-5"
    >
      <div class="loader" />
      <p class="mt-3" v-t="`ai_assistant.load_messages.${current}`" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ailoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);

  &_img {
    width: 262px; height: 320px;
    margin-top: -64px;
    transform: scale(0.8);
    transform-origin: center bottom;
  }

  &_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.bg-bonplan {
  background: url('~@app/assets/img/ai_assistant/sprite.png') -10px -10px;
}

.bg-super {
  background: url('~@app/assets/img/ai_assistant/sprite.png') -10px -350px;
}

.bg-idee {
  background: url('~@app/assets/img/ai_assistant/sprite.png') -10px -690px;
}

.bg-reflexion {
  background: url('~@app/assets/img/ai_assistant/sprite.png') -10px -1030px;
}

.bg-ecriture {
  background: url('~@app/assets/img/ai_assistant/sprite.png') -10px -1370px;
}
</style>
