<script>
import _pick from 'lodash/pick';
import { mapGetters } from 'vuex';
import { genericForms } from '@shared/mixins/forms';
import aiAssistantMixin from '@app/mixins/aiAssistant';
import APIStore from '@app/services/API/Store';
import AILoader from '@app/components/AILoader.vue';

export default {
  mixins: [genericForms, aiAssistantMixin],
  components: {
    AILoader,
  },
  props: {
    hasExplanation: {
      type: Boolean,
      default: true,
    },
  },
  static: {
    cost: 1,
  },
  form: {
    what: {
      type: 'text',
      column: 'is-12',
      label: 'Quel est le titre ou la thématique de votre formation ? (obligatoire)',
      tooltipAttrs: { multilined: true },
      tooltipHTML: `
        <p>
          Afin de générer la meilleure proposition de valeur,
          j'ai besoin du titre de votre formation, ou au moins
          de sa thématique.
        </p>
        <p class="mt-2">
          Vous pouvez aussi mettre plusieurs mots-clés séparés
          par des virgules.
        </p>
      `,
      inputAttrs: {
        placeholder: 'Apprendre le SEO en 5 étapes',
        required: true,
        maxlength: 100,
      },
    },
    tone_1: {
      type: 'select',
      column: 'is-6',
      label: 'Ton du texte #1',
      options: {
        empty: 'Aléatoire',
        familiarity: 'Tutoiement',
        formality: 'Vouvoiement',
      },
      inputAttrs: {
        required: true,
      },
    },
    tone_2: {
      type: 'select',
      column: 'is-6',
      label: 'Ton du texte #2',
      options: {
        empty: 'Aléatoire',
        clickbait: 'Piège à clic (clickbait)',
        secret: 'Énigmatique (Mystérieux)',
        ask: 'Questionnement',
      },
      inputAttrs: {
        required: true,
      },
    },
    who: {
      type: 'text',
      column: 'is-12',
      label: 'À qui s\'adresse votre formation ?',
      tooltipAttrs: { multilined: true },
      tooltip: `
        Une page de vente efficace doit s'adresser
        à votre coeur de cible de la manière la plus précise possible.
      `,
      inputAttrs: {
        placeholder: 'les entrepreneurs qui ne veulent pas faire de publicité payante',
        maxlength: 120,
      },
    },
    problem: {
      type: 'text',
      column: 'is-12',
      label: 'Quel problème, rencontré par votre cible, cette formation résout-elle ?',
      tooltipAttrs: { multilined: true },
      tooltip: `
        Expliquez en une phrase le problème ou le besoin qu'a
        votre cible et que votre formation permettra de résoudre.
      `,
      inputAttrs: {
        placeholder: 'Le SEO, c\'est long et trop compliqué à apprendre',
        maxlength: 120,
      },
    },
    result: {
      type: 'textarea',
      column: 'is-12',
      label: 'Quel sera le bénéfice pour l\'acheteur ?',
      tooltipAttrs: { multilined: true },
      tooltip: `
        Expliquez en une phrase les bénéfices de votre formation
        pour votre cible.
      `,
      inputAttrs: {
        placeholder: 'Vous saurez attirer plus de clients',
        maxlength: 240,
        rows: 2,
      },
    },
  },
  data() {
    return {
      newOptions: {
        type: 'value_proposition',
        what: '',
        who: '',
        problem: '',
        result: '',
        tone_1: 'empty',
        tone_2: 'empty',
        tones: [],
      },
    };
  },
  computed: {
    ...mapGetters('auth', {
      authStore: 'store',
    }),
    baseFields() {
      return _pick(this.$options.form, [
        'what',
        'tone_1',
        'tone_2',
      ]);
    },
    advancedFields() {
      return _pick(this.$options.form, [
        'who',
        'problem',
        'result',
      ]);
    },
  },
  methods: {
    action() {
      const newOptions = { ...this.newOptions };

      newOptions.tones = [];

      if (newOptions.tone_1 !== 'empty') {
        newOptions.tones.push(newOptions.tone_1);
      }

      if (newOptions.tone_2 !== 'empty') {
        newOptions.tones.push(newOptions.tone_2);
      }

      delete newOptions.tone_1;
      delete newOptions.tone_2;

      Object.keys(newOptions)
        .forEach((v) => newOptions[v] === '' && (delete newOptions[v]));

      return APIStore.generateAIContent(this.authStore.uuid, newOptions)
        .then(({ data }) => {
          this.showContent(data);
          this.removeCredits();
        });
    },
  },
};
</script>

<template>
  <div>
    <template v-if="hasExplanation">
      <h3 class="title is-5">
        Rédigez une proposition de valeur
        <b-tooltip position="is-left">
          <template #content>
            <span v-t="'ai_assistant.credit_cost'" />
          </template>
          <span class="tag is-outlined">
            {{ $options.static.cost }}
            crédit{{ $options.static.cost > 1 ? 's' : '' }}
          </span>
        </b-tooltip>
      </h3>
      <div class="content">
        <p>
          La proposition de valeur résume votre formation
          en 1 à 2 phrases maximum.
        </p>
        <p>
          C'est ce qui doit être utilisé comme description courte
          de votre formation, mais aussi comme base pour vos canaux
          de communication.
        </p>
      </div>
    </template>

    <form
      class="box"
      @submit.prevent="dataIsValid(isLoading) && hasEnoughCredits() && handle()"
    >
      <div class="columns is-multiline">
        <div
          v-for="(field, k) in baseFields"
          :key="k"
          class="column mb-0"
          :class="field.column"
        >
          <GeneratorField
            :fieldName="k"
            :fieldDefinition="field"
            :formData.sync="newOptions"
          />
        </div>
      </div>
      <AppToggleSection>
        <template #title>
          Avez-vous plus de précisions ?
        </template>
        <div class="columns is-multiline">
          <div
            v-for="(field, k) in advancedFields"
            :key="k"
            class="column mb-0"
            :class="field.column"
          >
            <GeneratorField
              :fieldName="k"
              :fieldDefinition="field"
              :formData.sync="newOptions"
            />
          </div>
        </div>
      </AppToggleSection>
      <div class="field has-text-right">
        <b-button
          type="is-primary"
          native-type="submit"
          icon-left="magic"
          :loading="isLoading"
        >
          Générer une proposition de valeur
        </b-button>
      </div>
    </form>

    <transition name="fade">
      <AILoader v-if="isLoading" />
    </transition>
  </div>
</template>
