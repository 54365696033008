<script>
export default {
  inject: ['viewerHasInsertCta'],
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onCopy() {
      this.$emit('close');
      this.$emit('success', this.content);
      this.$copySuccess();
    },
    insert() {
      this.$emit('close');
      this.$emit('success', this.content);
    },
  },
};
</script>

<template>
  <AppCardModal @close="$emit('close')">
    <template #title>
      Votre contenu
    </template>
    <template #body>
      <div class="content wspace-preline">{{
        content.generated_content
      }}</div>
    </template>
    <template #foot>
      <b-button type="is-text" @click="$emit('close')">
        Fermer
      </b-button>
      <b-button
        v-if="viewerHasInsertCta"
        type="is-primary"
        icon-left="clipboard"
        v-clipboard:copy="content.generated_content"
        v-clipboard:success="insert"
      >
        Insérer dans le champ
      </b-button>
      <b-button
        v-else
        type="is-primary"
        icon-left="clipboard"
        v-clipboard:copy="content.generated_content"
        v-clipboard:success="onCopy"
        v-clipboard:error="() => $copyError()"
      >
        Copier
      </b-button>
    </template>
  </AppCardModal>
</template>
