<script>
import APIPayment from '@app/services/API/Payment';

export default {
  data: () => ({
    quantity: {
      PACK_S: 1,
      PACK_M: 1,
      PACK_L: 1,
    },
  }),
  methods: {
    buy(price, quantity = 1) {
      const loader = this.$buefy.loading.open();
      APIPayment.getPaymentLink({ stripe_price_id: price, quantity })
        .then(({ data }) => (window.location.href = data.link))
        .catch((error) => {
          this.$errorHandlers.axios(error);
          // because location.href takes time
          loader.close();
        });
    },
  },
};
</script>

<template>
  <section class="aicbcomp box pt-10 pb-30 mnh-full flex-center-center-column">
    <b-button
      class="aicbcomp_close"
      icon-left="times"
      @click="$emit('close')"
    />

    <h1 class="title has-text-centered">
      Achetez des crédits pour un copywriter
      <br>
      spécialisé en vente de formation
    </h1>
    <div class="content is-medium mw-640 has-text-centered">
      <p>
        En utilisant mes services, vous faites
        <strong>de grosses économies</strong>
        <br>
        par rapport à un copywriter freelance
        (<a
            class="has-text-black tdecoration-underline has-text-weight-bold"
          href="https://www.malt.fr/t/barometre-tarifs/communication"
          target="_blank"
          >441 &euro; / jour en moyenne</a>).
      </p>
      <p>
        Je ne suis bien évidemment pas parfait,
        mais en tant qu'
        <b-tooltip label="Créé grâce à l'intelligence artificielle" dashed>
          assistant IA
        </b-tooltip>
        <br>
        je vous ferai <strong>gagner un temps fou !</strong>
      </p>
    </div>

    <div class="columns is-multiline flex-none w-full mw-widescreen">
      <div class="column is-4">
        <figure class="image w-144">
          <img
            src="@app/assets/img/ai_assistant/ecriture.png"
            alt="Votre assistant Copywriter"
          >
        </figure>
        <article class="box is-black content">
          <header class="flex-between-center">
            <h2 class="title is-5 has-text-black mb-2">
              {{ $constants.AI_PRICES.CREDITS.PACK_S }} crédits
            </h2>

            <p class="tag is-outlined is-medium">
              <b-tooltip label="Prix de lancement">
                <strong>-40%</strong>
              </b-tooltip>
            </p>
          </header>
          <p>
            <span class="is-size-2 has-text-weight-bold has-text-success">
              {{ $constants.AI_PRICES.PROMO.PACK_S }}&euro;
            </span>
            <del class="is-size-5 has-text-weight-bold">
              {{ $constants.AI_PRICES.REAL.PACK_S }}&euro;
            </del>
            <span class="is-block is-size-8 has-text-grey-dark">
              Un seul paiement. Prix de lancement. Tarifs TTC.
            </span>
          </p>
          <p>
            Idéal pour trouver l'inspiration
            et ensuite reprendre la main par vous-même.
          </p>
          <hr>
          <p>
            - Environ 12 000 mots
          </p>
          <p class="has-text-weight-bold">
            Générez en 1 clic des :
          </p>
          <ul class="is-size-8">
            <li>Pages de vente complètes</li>
            <li>Idées de titres de formation</li>
            <li>Descriptions impactantes</li>
            <li>Structures de pages de vente</li>
          </ul>
          <p class="has-text-weight-bold">
            En 1 min chrono, vous aurez vos contenus
          </p>
          <p>
            Plusieurs heures de travail économisées !
          </p>
          <p class="is-flex">
            <b-dropdown class="mr-3" position="is-top-right">
              <template #trigger>
                <b-button class="py-6 px-5">
                  {{ quantity.PACK_S }}
                </b-button>
              </template>
              <b-dropdown-item class="w-280" custom>
                <b-numberinput
                  type="is-white"
                  min="1"
                  max="10"
                  expanded
                  v-model="quantity.PACK_S"
                />
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              type="is-black"
              class="py-6"
              expanded
              @click="buy($constants.AI_PRICE_IDS.PACK_S, quantity.PACK_S)"
            >
              Acheter {{ $constants.AI_PRICES.CREDITS.PACK_S * quantity.PACK_S }} crédits
            </b-button>
          </p>
        </article>
      </div>
      <div class="column is-4">
        <figure class="image w-144 mx-auto">
          <img
            src="@app/assets/img/ai_assistant/super.png"
            alt="Votre assistant Copywriter"
          >
        </figure>
        <article class="box is-warning content">
          <header class="flex-between-center">
            <h2 class="title is-5 has-text-black mb-2">
              {{ $constants.AI_PRICES.CREDITS.PACK_M }} crédits

              <span class="is-size-7 has-text-success">
                + {{ $constants.AI_PRICES.GIFT.PACK_M }} offerts
              </span>
            </h2>

            <p class="tag is-outlined is-medium">
              <b-tooltip label="Prix de lancement">
                <strong>-40%</strong>
              </b-tooltip>
            </p>
          </header>
          <p>
            <span class="is-size-2 has-text-warning has-text-weight-bold has-text-success">
              {{ $constants.AI_PRICES.PROMO.PACK_M }}&euro;
            </span>
            <del class="is-size-5 has-text-weight-bold">
              {{ $constants.AI_PRICES.REAL.PACK_M }}&euro;
            </del>
            <span class="is-block is-size-8 has-text-grey-dark">
              Un seul paiement. Prix de lancement. Tarifs TTC.
            </span>
          </p>
          <p>
            C'est le nombre de crédits parfait pour
            profitez de mon expertise.
          </p>
          <hr>
          <p>
            - Environ 30 000 mots
          </p>
          <p class="has-text-weight-bold">
            Générez en 1 clic des :
          </p>
          <ul class="is-size-8">
            <li>Pages de vente complètes</li>
            <li>Idées de titres de formation</li>
            <li>Descriptions impactantes</li>
            <li>Structures de pages de vente</li>
          </ul>
          <p class="has-text-weight-bold">
            En 1 min chrono, vous aurez vos contenus
          </p>
          <p>
            Plusieurs heures de travail économisées !
          </p>
          <p class="is-flex">
            <b-dropdown class="mr-3" position="is-top-right">
              <template #trigger>
                <b-button class="py-6 px-5">
                  {{ quantity.PACK_M }}
                </b-button>
              </template>
              <b-dropdown-item class="w-280" custom>
                <b-numberinput
                  type="is-white"
                  min="1"
                  max="10"
                  expanded
                  v-model="quantity.PACK_M"
                />
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              type="is-warning"
              class="py-6"
              expanded
              @click="buy($constants.AI_PRICE_IDS.PACK_M, quantity.PACK_M)"
            >
              Acheter {{ $constants.AI_PRICES.CREDITS.PACK_M * quantity.PACK_M }} crédits
            </b-button>
          </p>
        </article>
      </div>
      <div class="column is-4">
        <figure class="image w-144 ml-auto">
          <img
            src="@app/assets/img/ai_assistant/idee.png"
            alt="Votre assistant Copywriter"
          >
        </figure>
        <article class="box is-black content">
          <header class="flex-between-center">
            <h2 class="title is-5 has-text-black mb-2">
              {{ $constants.AI_PRICES.CREDITS.PACK_L }} crédits

              <span class="is-size-7 has-text-success">
                + {{ $constants.AI_PRICES.GIFT.PACK_L }} offerts
              </span>
            </h2>

            <p class="tag is-outlined is-medium">
              <b-tooltip label="Prix de lancement">
                <strong>-40%</strong>
              </b-tooltip>
            </p>
          </header>
          <p>
            <span class="is-size-2 has-text-weight-bold has-text-success">
              {{ $constants.AI_PRICES.PROMO.PACK_L }}&euro;
            </span>
            <del class="is-size-5 has-text-weight-bold">
              {{ $constants.AI_PRICES.REAL.PACK_L }}&euro;
            </del>
            <span class="is-block is-size-8 has-text-grey-dark">
              Un seul paiement. Prix de lancement. Tarifs TTC.
            </span>
          </p>
          <p>
            Avec ce pack, vous pouvez même faire du A/B
            testing avec mes textes.
          </p>
          <hr>
          <p>
            - Environ 75 000 mots
          </p>
          <p class="has-text-weight-bold">
            Générez en 1 clic des :
          </p>
          <ul class="is-size-8">
            <li>Pages de vente complètes</li>
            <li>Idées de titres de formation</li>
            <li>Descriptions impactantes</li>
            <li>Structures de pages de vente</li>
          </ul>
          <p class="has-text-weight-bold">
            En 1 min chrono, vous aurez vos contenus
          </p>
          <p>
            Plusieurs heures de travail économisées !
          </p>
          <p class="is-flex">
            <b-dropdown class="mr-3" position="is-top-right">
              <template #trigger>
                <b-button class="py-6 px-5">
                  {{ quantity.PACK_L }}
                </b-button>
              </template>
              <b-dropdown-item class="w-280" custom>
                <b-numberinput
                  type="is-white"
                  min="1"
                  max="10"
                  expanded
                  v-model="quantity.PACK_L"
                />
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              type="is-black"
              class="py-6"
              expanded
              @click="buy($constants.AI_PRICE_IDS.PACK_L, quantity.PACK_L)"
            >
              Acheter {{ $constants.AI_PRICES.CREDITS.PACK_L * quantity.PACK_L }} crédits
            </b-button>
          </p>
        </article>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.aicbcomp {
  position: relative;

  &_close {
    position: absolute;
    top: pxToRem(20px);
    right: pxToRem(20px);
  }
}
</style>
