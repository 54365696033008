import AICreditsBox from '@app/components/AICreditsBox.vue';
import AIContentViewer from '@app/components/AIContentViewer.vue';

export default {
  provide() {
    return { viewerHasInsertCta: false };
  },
  methods: {
    hasEnoughCredits() {
      const credits = this.$store.getters['auth/aiCreditsLeft'];

      if (credits < this.$options.static.cost) {
        this.$buefy.dialog.confirm({
          type: 'is-warning',
          title: 'Vous n\'avez pas assez de crédits',
          message: `
            Il vous reste ${credits} credit${credits > 1 ? 's' : ''},
            vous devez en acheter pour effectuer cette tâche.
          `.trim(),
          focusOn: 'cancel',
          confirmText: 'Acheter des crédits',
          onConfirm: () => this.$buefy.modal.open({
            parent: this,
            component: AICreditsBox,
            canCancel: false,
            fullScreen: true,
          }),
        });

        return false;
      }

      return true;
    },
    removeCredits() {
      this.$store.commit(
        'auth/updateAICredits',
        this.$options.static.cost,
      );
    },
    showContent(content) {
      this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: AIContentViewer,
        hasModalCard: true,
        width: 640,
        props: {
          content,
        },
        events: {
          success: (content) => this.$emit('content', content),
        },
      });
    },
  },
};
